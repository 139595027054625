import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
} from '@material-ui/core';
import TablePaginationActions from '../TablePaginationActions';
import HangoutListTableRow from './HangoutListTableRow';

const HangoutListTable = (props) => {
  const {
    hangouts,
    pageTotal,
    page,
    setPage,
    perPageCount,
    toHangoutUserAssignment,
  } = props;

  return (
    <TableContainer component={Box}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>課程名稱</TableCell>
            <TableCell>授課老師</TableCell>
            <TableCell>操作</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {hangouts.map((hangout) => (
            <HangoutListTableRow
              key={hangout.HangoutId}
              hangout={hangout}
              toHangoutUserAssignment={toHangoutUserAssignment}
            />
          ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[perPageCount]}
              colSpan={4}
              count={pageTotal * perPageCount}
              rowsPerPage={perPageCount}
              page={page - 1}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={(event, currentPage) => {
                setPage(currentPage + 1);
              }}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default HangoutListTable;
