import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
  Button,
} from '@material-ui/core';
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
import TeacherUserList from './TeacherUserList';
import SimpleMemberList from '../Member/SimpleMemberList';
import { remove } from 'lodash';
import teacherAPI from '../../middleware/teacherAPI';
import Swal from 'sweetalert2';

const TeacherUserModal = (props) => {
  const { isOpen, close, teacher } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [pageState, setPageState] = useState({
    step: 'list',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState([]);

  const handleClose = () => {
    setPageState({
      step: 'list',
    });
    setIsLoading(false);
    setSelectedMembers([]);
    close();
  };

  const handleAddUser = () => {
    setPageState({
      ...pageState,
      step: 'selectMember',
    });
  };

  const handleCancelSelect = () => {
    setPageState({
      ...pageState,
      step: 'list',
    });
    setSelectedMembers([]);
  };

  const handleLink = () => {
    setIsLoading(true);

    teacherAPI
      .addUsers(teacher.teacherId, selectedMembers)
      .then((rs) => {
        handleCancelSelect();
        Swal.fire({
          title: '加入完成',
          icon: 'success',
        });
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  const selectMember = (member) => {
    const newSelectedMembers = [...selectedMembers];

    if (selectedMembers.includes(member.Id)) {
      remove(
        newSelectedMembers,
        (selectedMember) =>
          parseInt(selectedMember, 10) === parseInt(member.Id, 10),
      );
    } else {
      newSelectedMembers.push(member.Id);
    }

    setSelectedMembers(newSelectedMembers);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isOpen}
      onClose={handleClose}
      fullWidth={true}>
      <DialogTitle>{teacher ? teacher.name : ''} 關聯會員</DialogTitle>
      <DialogContent>
        {pageState.step === 'list' && !isLoading && (
          <TeacherUserList
            teacherId={teacher ? teacher.teacherId : undefined}
          />
        )}

        {pageState.step === 'selectMember' && !isLoading && (
          <SimpleMemberList
            selectAction={selectMember}
            selectedMembers={selectedMembers}
          />
        )}
      </DialogContent>
      <DialogActions>
        {pageState.step === 'list' && !isLoading && (
          <Button
            color="primary"
            startIcon={<AddCircleOutlinedIcon />}
            variant="contained"
            onClick={handleAddUser}
            fullWidth>
            加入會員
          </Button>
        )}

        {pageState.step === 'selectMember' && !isLoading && (
          <>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              disabled={isLoading || selectedMembers.length <= 0}
              onClick={handleLink}>
              加入關聯
              {selectedMembers.length > 0 && (
                <span> ({selectedMembers.length})</span>
              )}
            </Button>
            <Button
              color="default"
              variant="contained"
              onClick={handleCancelSelect}>
              取消
            </Button>
          </>
        )}

        <Button color="primary" onClick={handleClose} disabled={isLoading}>
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TeacherUserModal;
