import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChromeReaderModeRoundedIcon from '@material-ui/icons/ChromeReaderModeRounded';
import CreateRoundedIcon from '@material-ui/icons/CreateRounded';
import Map from '../../data/Map';
import { trim, map, unescape } from 'lodash';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import UserAssignmentModal from './UserAssignmentModal';

const useStyles = makeStyles((theme) => ({
  assignmentTitle: {
    fontSize: '0.8rem',
    fontWeight: '600',
    color: '#999',
  },
  assignmentName: {
    fontSize: '1rem',
  },
  assignmentCounts: {
    marginLeft: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    color: '#999',
  },
  assignmentCount: {
    width: '80px',
    textAlign: 'center',
    border: '1px solid #eee',
    borderRadius: '6px',
    margin: '3px 6px',
    padding: '3px 6px',
    backgroundColor: '#eee',
  },
  assignmentCountValue: {
    fontSize: '0.9rem',
  },
  assignmentCountTitle: {
    fontSize: ' 0.9rem',
    fontWeight: '600',
    letterSpacing: '1px',
  },
  assignmentCountHighLightRed: {
    backgroundColor: '#ff3030',
    color: '#fff',
  },
  assignmentCountHighLightGreen: {
    backgroundColor: '#009700',
    color: '#fff',
  },
}));

const UserAssignmentsAccordion = (props) => {
  const {
    isExpanded,
    clickAction,
    assignment,
    userAssignments,
    isLoading,
    studentCount,
    grade,
  } = props;
  const classes = useStyles();
  const [isUserAssignmentModalOpen, setIsUserAssignmentModalOpen] = useState(
    false,
  );
  const [userAssignmentModalData, setUserAssignmentModalData] = useState();

  const handleChange = () => {
    clickAction(assignment);
  };

  const openUserAssignmentModal = (userAssignment) => {
    setIsUserAssignmentModalOpen(true);
    setUserAssignmentModalData(userAssignment);
  };

  const closeUserAssignmentModal = () => {
    setIsUserAssignmentModalOpen(false);
    setUserAssignmentModalData(undefined);
  };

  const submissionCountText =
    trim(assignment.submissionCount) === '' ? '無限' : '一次';
  const submissionTypeText = Map.SubmissionTypes[assignment.submissionType]
    ? Map.SubmissionTypes[assignment.submissionType].name
    : assignment.submissionType;

  return (
    <Accordion
      expanded={isExpanded}
      onChange={handleChange}
      disabled={isLoading}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div>
          <div className={classes.assignmentTitle}>
            <ChromeReaderModeRoundedIcon
              style={{ verticalAlign: 'bottom' }}
              fontSize="small"
            />{' '}
            作業
          </div>

          <div className={classes.assignmentName}>{assignment.name}</div>
        </div>

        <div className={classes.assignmentCounts}>
          <div
            className={`${classes.assignmentCount} ${
              assignment.canGradeStudentCount > 0
                ? classes.assignmentCountHighLightRed
                : ''
            }`}>
            <div className={classes.assignmentCountValue}>
              {assignment.canGradeStudentCount}
            </div>
            <div className={classes.assignmentCountTitle}>待批改</div>
          </div>

          <div
            className={`${classes.assignmentCount} ${
              assignment.passStudentCount > 0
                ? classes.assignmentCountHighLightGreen
                : ''
            }`}>
            <div className={classes.assignmentCountValue}>
              {assignment.passStudentCount}
            </div>
            <div className={classes.assignmentCountTitle}>通過</div>
          </div>

          <div className={classes.assignmentCount}>
            <div className={classes.assignmentCountValue}>{studentCount}</div>
            <div className={classes.assignmentCountTitle}>學生總計</div>
          </div>
        </div>
      </AccordionSummary>

      <AccordionDetails>
        <Grid
          container
          direction="row"
          justifycontent="flex-start"
          alignItems="stretch">
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              display="inline"
              gutterBottom
              align="justify"
              color="textSecondary"
              paragraph
              style={{ marginRight: '6px' }}>
              {`提交次數 ${submissionCountText}`}
            </Typography>
            <Typography
              variant="subtitle1"
              display="inline"
              gutterBottom
              align="justify"
              color="textSecondary"
              paragraph>
              {`檔案 ${submissionTypeText}`}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              display="block"
              component="div"
              variant="body1"
              gutterBottom>
              <div
                dangerouslySetInnerHTML={{
                  __html: assignment.description,
                }}
              />
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>學生</TableCell>
                    <TableCell>檔案</TableCell>
                    <TableCell>上傳日期</TableCell>
                    <TableCell>批改狀態</TableCell>
                    <TableCell>批改備註</TableCell>
                    <TableCell>動作</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {Array.isArray(userAssignments)
                    ? map(userAssignments, (userAssignment) => {
                        return (
                          <TableRow
                            key={`${userAssignment.userAssignmentId}-${userAssignment.realName}`}>
                            <TableCell>{userAssignment.realName}</TableCell>
                            <TableCell>{userAssignment.fileName}</TableCell>
                            <TableCell>
                              {trim(userAssignment.hanginTime) !== ''
                                ? moment(userAssignment.hanginTime).format(
                                    'YYYY-MM-DD HH:MM:ss',
                                  )
                                : ''}
                            </TableCell>
                            <TableCell>
                              {Map.GradeStatus[userAssignment.gradeStatus]
                                ? Map.GradeStatus[userAssignment.gradeStatus]
                                    .name
                                : '尚未繳交'}
                            </TableCell>
                            <TableCell
                              dangerouslySetInnerHTML={{
                                __html:
                                  trim(userAssignment.gradeFeedback) !== ''
                                    ? unescape(userAssignment.gradeFeedback)
                                    : '',
                              }}
                            />
                            <TableCell>
                              <Button
                                variant="contained"
                                color="primary"
                                startIcon={<CreateRoundedIcon />}
                                disabled={
                                  isLoading ||
                                  trim(userAssignment.fileName) === ''
                                }
                                onClick={() => {
                                  openUserAssignmentModal(userAssignment);
                                }}>
                                批改
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>

        <UserAssignmentModal
          isOpen={isUserAssignmentModalOpen}
          close={closeUserAssignmentModal}
          assignment={assignment}
          userAssignment={userAssignmentModalData}
          grade={grade}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default UserAssignmentsAccordion;
