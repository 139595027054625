import React, { useEffect, useState } from 'react';
import BreakCrumbs from '../../components/BreakCrumbs';
import {
  Box,
  Grid,
  Button,
  Paper,
  Typography,
  Divider,
} from '@material-ui/core';
import { alertError } from '../../Util/Util';
import _ from 'lodash';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import TableLoading from '../../components/TableLoading';
import TableError from '../../components/TableError';
import TableEmpty from '../../components/TableEmpty';
import TeacherTable from '../../components/Teacher/TeacherTable';
import teacherAPI from '../../middleware/teacherAPI';
import { useHistory } from 'react-router-dom';
import TeacherUserModal from './TeacherUserModal';

const TeacherList = (props) => {
  const [pageStatus, setPageStatus] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [teachers, setTeachers] = useState(undefined);
  const [isTeacherUserModalOpen, setIsTeacherUserModalOpen] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState();
  const history = useHistory();

  useEffect(() => {
    setPageStatus('loading');

    teacherAPI.getList().then(
      (response) => {
        setTeachers(response);
        setPageStatus('list');
      },
      (error) => {
        alertError('無法取得師資列表', error);
        setPageStatus('error');
      },
    );
  }, []);

  const refreshTeachers = (action, newTeacher, deleteTeacherId) => {
    let newTeachers = [...teachers];

    switch (action) {
      case 'add':
        newTeachers.push(newTeacher);
        break;

      case 'update':
        const newTeacherIndex = _.findIndex(newTeachers, {
          teacherId: newTeacher.teacherId,
        });

        if (newTeacherIndex !== -1) {
          newTeachers[newTeacherIndex] = newTeacher;
        }
        break;

      case 'delete':
        _.remove(newTeachers, (teacher) => {
          return teacher.teacherId === deleteTeacherId;
        });
        break;
    }

    setTeachers(newTeachers);
  };

  const toggleStatus = (teacher, isOnline) => {
    const newTeacher = { ...teacher, status: isOnline ? 1 : 0 };
    let params = new URLSearchParams();

    params.append('TeacherId', newTeacher.teacherId);
    params.append('Status', newTeacher.status);
    params.append('Name', newTeacher.name);
    params.append('Introduction', newTeacher.introduction);
    params.append('ImageName', newTeacher.imageName);

    setIsProcessing(true);

    teacherAPI
      .edit(params)
      .then(
        (response) => {
          refreshTeachers('update', newTeacher);
        },
        (error) => {
          alertError(error, '更改失敗');
        },
      )
      .then(() => {
        setIsProcessing(false);
      });
  };

  const deleteTeacher = (teacherId) => {
    setIsProcessing(true);

    return teacherAPI
      .delete(teacherId)
      .then(
        (response) => {
          refreshTeachers('delete', undefined, teacherId);
        },
        (error) => {
          alertError(error);
        },
      )
      .then(() => {
        setIsProcessing(false);
      });
  };

  const openSettingUserModal = (teacher) => {
    setIsTeacherUserModalOpen(true);
    setSelectedTeacher(teacher);
  };

  const closeSettingUserModal = () => {
    setIsTeacherUserModalOpen(false);
    setSelectedTeacher(undefined);
  };

  return (
    <div className="popular pagefadeIn">
      <BreakCrumbs path={props.match.url}></BreakCrumbs>

      <Box component={Paper} square elevation={2} p={1} mt={3} mb={3}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center">
          <Grid item xs={6}>
            <Typography variant="h4" color="textPrimary" gutterBottom>
              師資列表
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Box textAlign="right">
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddRoundedIcon />}
                disabled={pageStatus === 'loading' || isProcessing}
                onClick={() => {
                  history.push('/Teacher/Create');

                  return false;
                }}>
                新增師資
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Divider light />

        {pageStatus === 'loading' && <TableLoading />}

        {pageStatus === 'error' && <TableError />}

        {pageStatus === 'list' &&
          (teachers && teachers.length > 0 ? (
            <TeacherTable
              teachers={teachers}
              toggleStatus={toggleStatus}
              isProcessing={isProcessing}
              deleteTeacher={deleteTeacher}
              openSettingUserModal={openSettingUserModal}
            />
          ) : (
            <TableEmpty />
          ))}
      </Box>

      <TeacherUserModal
        isOpen={isTeacherUserModalOpen}
        close={closeSettingUserModal}
        teacher={selectedTeacher}
      />
    </div>
  );
};

export default TeacherList;
