import React from 'react';
import { Checkbox, Grid } from '@material-ui/core';

const MemberRow = (props) => {
  const { member, isCheck, selectAction } = props;

  const handleSelect = (e) => {
    e.preventDefault();
  };

  const handleRowClick = () => {
    if (selectAction) {
      selectAction(member);
    }
  };

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      style={{
        cursor: selectAction ? 'pointer' : 'auto',
        margin: '12px auto',
        padding: '3px 0',
      }}
      onClick={handleRowClick}>
      {selectAction && (
        <Grid item xs={2}>
          <Checkbox checked={isCheck} onChange={handleSelect} />
        </Grid>
      )}

      <Grid item xs={2}>
        {member.Id}
      </Grid>

      <Grid item xs={3}>
        {member.RealName}
      </Grid>

      <Grid item xs={5}>
        <div>{member.Email}</div>
        <div>{member.CellPhone}</div>
      </Grid>
    </Grid>
  );
};

export default MemberRow;
