import instance from './API';
import { join } from 'lodash';

const basePath = '/Teacher';
const teacherUserPath = '/TeacherGroup';

const teacherAPI = {
  getList: (params) => {
    const urlQuery = params ? '?' + new URLSearchParams(params).toString() : '';

    return instance.get(basePath + urlQuery);
  },
  get: (teacherId) => {
    return instance.get(basePath + '/' + teacherId);
  },
  add: (params) => {
    return instance.post(basePath, params);
  },
  edit: (params) => {
    return instance.put(basePath, params);
  },
  delete: (teacherId) => {
    return instance.delete(basePath + '?teacherId=' + teacherId);
  },
  getUsers: (teacherId) => {
    return instance.get(teacherUserPath + `/${teacherId}`);
  },
  delUser: (teacherId, userId) => {
    return instance.delete(teacherUserPath + `/${teacherId}/User/${userId}`);
  },
  addUsers: (teacherId, userIds) => {
    return instance.post(teacherUserPath + `/${teacherId}`, null, {
      params: {
        userIds: join(userIds, ','),
      },
    });
  },
};

export default teacherAPI;
