import React, { useEffect, useState, useMemo } from 'react';
import {
  Box,
  Grid,
  Button,
  Paper,
  Typography,
  Divider,
  TextField,
} from '@material-ui/core';
import { LIST_PAGE_COUNT } from '../../data/common';
import SearchIcon from '@material-ui/icons/Search';
import { map } from 'lodash';
import TableLoading from '../../components/TableLoading';
import TableEmpty from '../../components/TableEmpty';
import { Pagination } from '@material-ui/lab';
import { fetchMembers } from '../../Util/fetchMethods';
import httpErrorHandle from '../../Util/httpErrorHandle';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import { logoutSuccess } from '../../store/action';
import MemberRow from '../../components/MemberManagement/MemberRow';

const SimpleMemberList = (props) => {
  const { selectAction, selectedMembers } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [searchs, setSearchs] = useState({
    keyword: '',
  });
  const [pageParams, setPageParams] = useState({
    current: 1,
    perPageCount: LIST_PAGE_COUNT,
  });
  const [memberTotalPages, setMemberTotalPages] = useState(0);
  const [members, setMembers] = useState([]);
  const dispatch = useDispatch();

  const dispatchLogoutSuccess = useMemo(() => {
    return bindActionCreators(logoutSuccess, dispatch);
  }, [dispatch, logoutSuccess]);

  const reset = () => {
    setIsLoading(false);
    setSearchs({
      keyword: '',
    });
    setPageParams({
      current: 0,
      perPageCount: LIST_PAGE_COUNT,
    });
    setMemberTotalPages(0);
    setMembers([]);
  };

  const handleSearch = () => {
    setPageParams({
      ...pageParams,
      current: 1,
    });
  };

  const getMembers = async () => {
    setIsLoading(true);

    fetchMembers(
      pageParams.current,
      pageParams.perPageCount,
      1,
      searchs.keyword,
    )
      .then((rs) => {
        const { MemberList, TotalPages } = rs.Result;
        setMembers(MemberList);
        setMemberTotalPages(TotalPages);
      })
      .catch((error) => {
        httpErrorHandle(
          error.httpCode,
          `fetchMembers Error: ${error.msg} httpErrorCode: ${error.httpCode}`,
          dispatchLogoutSuccess,
        );
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  const handleChangePage = (event, value) => {
    setPageParams({
      ...pageParams,
      current: value,
    });
  };

  useEffect(() => {
    getMembers();
  }, [pageParams]);

  return (
    <div className="popular pagefadeIn">
      <Box component={Paper} square elevation={2} p={1} mt={3} mb={3}>
        <Typography variant="h4" color="textPrimary" gutterBottom>
          會員列表
        </Typography>

        <Divider light />

        <Box pt={2}>
          <form noValidate autoComplete="off">
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              spacing={1}>
              <Grid item xs={7}>
                <TextField
                  label="使用手機或姓名搜尋"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={searchs.keyword}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      handleSearch();
                    }
                  }}
                  onChange={(e) => {
                    setSearchs({
                      ...searchs,
                      keyword: e.target.value,
                    });
                  }}
                />
              </Grid>

              <Grid item xs={5}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  startIcon={<SearchIcon />}
                  onClick={handleSearch}>
                  搜尋會員
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>

        {isLoading && <TableLoading />}

        {!isLoading && members.length <= 0 && <TableEmpty message="沒有會員" />}

        {!isLoading && members.length > 0 && (
          <>
            {map(members, (member) => (
              <MemberRow
                key={member.Id}
                member={member}
                selectAction={selectAction}
                isCheck={selectedMembers.includes(member.Id)}
              />
            ))}

            <Pagination
              count={memberTotalPages}
              color="primary"
              disabled={isLoading}
              shape="rounded"
              page={pageParams.current}
              onChange={handleChangePage}
            />
          </>
        )}
      </Box>
    </div>
  );
};

export default SimpleMemberList;
