import React from 'react';
import { TableCell, TableRow, Button } from '@material-ui/core';
import SubjectRoundedIcon from '@material-ui/icons/SubjectRounded';

const HangoutListTableRow = (props) => {
  const { hangout, toHangoutUserAssignment } = props;

  const handleTotoHangoutUserAssignmentClick = () => {
    toHangoutUserAssignment(hangout);
  };

  return (
    <TableRow>
      <TableCell>{hangout.HangoutId}</TableCell>

      <TableCell>{hangout.Name}</TableCell>

      <TableCell>{hangout.Teacher.Name}</TableCell>

      <TableCell align="right">
        <Button
          startIcon={<SubjectRoundedIcon />}
          color="primary"
          variant="contained"
          onClick={handleTotoHangoutUserAssignmentClick}>
          作業列表
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default HangoutListTableRow;
