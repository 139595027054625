import instance from './API';

const hangoutPath = '/Hangout';

const hangoutAPI = {
  getUserAssignments: (hangoutId, assignmentId) => {
    return instance.get(
      `${hangoutPath}/${hangoutId}/Assignment/${assignmentId}`,
    );
  },
  gradeUserAssignment: (userAssignmentId, gradeStatus, gradeFeedback) => {
    return instance.put(`/UserAssignment/${userAssignmentId}`, {
      Status: gradeStatus,
      Feedback: gradeFeedback,
    });
  },
};

export default hangoutAPI;
