import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
} from '@material-ui/core';
import _ from 'lodash';
import TeacherTableRow from './TeacherTableRow';
import TablePaginationActions from '../TablePaginationActions';
import { LIST_PAGE_COUNT } from '../../data/common';
import { useHistory, useLocation } from 'react-router-dom';

const TeacherTable = (props) => {
  const {
    teachers,
    toggleStatus,
    isProcessing,
    deleteTeacher,
    openSettingUserModal,
  } = props;
  const [isInitDone, setIsInitDone] = useState(false);
  const [tableTeachers, setTableTeachers] = useState([]);
  const [page, setPage] = useState(() => {
    return 0;
  });
  const [perPageRows, setPerPageRows] = useState(_.parseInt(LIST_PAGE_COUNT));
  const [count, setCount] = useState(teachers.length);
  const history = useHistory();
  const location = useLocation();

  const checkPageAndPerPageRows = (checkPage, checkPerPageRows) => {
    let needChangePage = false;
    let lastPage = checkPage;

    if (checkPage * checkPerPageRows >= teachers.length) {
      needChangePage = true;

      lastPage = Math.floor(teachers.length / checkPerPageRows);

      if (teachers.length % checkPerPageRows === 0) {
        lastPage = lastPage - 1;
      }
    }

    return {
      needChangePage,
      lastPage,
    };
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const queryPage = _.parseInt(queryParams.get('page'));
    const queryPerPageRows = _.parseInt(queryParams.get('perPageRows'));

    if (queryPage > 0) {
      const nowPerPageRows =
        queryPerPageRows > 0 ? queryPerPageRows : perPageRows;
      let nowPage = queryPage;

      const checkPageResult = checkPageAndPerPageRows(
        queryPage,
        nowPerPageRows,
      );

      if (checkPageResult.needChangePage) {
        nowPage = checkPageResult.lastPage;
      }

      setPage(nowPage);
    }

    if (queryPerPageRows > 0) {
      setPerPageRows(queryPerPageRows);
    }

    setIsInitDone(true);
  }, []);

  useEffect(() => {
    if (isInitDone === true) {
      const checkPageResult = checkPageAndPerPageRows(page, perPageRows);

      if (checkPageResult.needChangePage) {
        changePage(checkPageResult.lastPage);
      } else {
        const startIndex = page * perPageRows;
        const endIndex = startIndex + perPageRows;

        setTableTeachers(_.slice(teachers, startIndex, endIndex));
        setCount(teachers.length);
      }
    }
  }, [teachers, page, perPageRows, isInitDone]);

  const changePage = (currentPage) => {
    setPage(_.parseInt(currentPage));
    history.push(
      '/Teacher?page=' + currentPage + '&perPageRows=' + perPageRows,
    );
  };

  const changePerPageRows = (currentPerPageRows) => {
    let nowPage = page;

    const checkPageResult = checkPageAndPerPageRows(page, currentPerPageRows);

    if (checkPageResult.needChangePage) {
      nowPage = checkPageResult.lastPage;
      setPage(checkPageResult.lastPage);
    }

    setPerPageRows(_.parseInt(currentPerPageRows));
    history.push(
      '/Teacher?page=' + nowPage + '&perPageRows=' + currentPerPageRows,
    );
  };

  return (
    <TableContainer component={Box}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>頭像</TableCell>
            <TableCell width="60%">名稱</TableCell>
            <TableCell>狀態</TableCell>
            <TableCell align="right">功能</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {_.map(tableTeachers, (teacher) => (
            <TeacherTableRow
              key={teacher.teacherId}
              teacher={teacher}
              isProcessing={isProcessing}
              toggleStatus={toggleStatus}
              deleteTeacher={deleteTeacher}
              openSettingUserModal={openSettingUserModal}
            />
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[LIST_PAGE_COUNT, 25, 50]}
              colSpan={5}
              count={count}
              rowsPerPage={perPageRows}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={(event, currentPage) => {
                changePage(currentPage);
              }}
              onChangeRowsPerPage={(event) => {
                changePerPageRows(event.target.value);
              }}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default TeacherTable;
