import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import Map from '../../data/Map';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import Editor from '../Editor';
import { unescape, escape } from 'lodash';
import Swal from 'sweetalert2';
import PDFViewer from '../PDFViewer';
import QuestionVideo from '../Question/QuestionVideo';

const UserAssignmentModal = (props) => {
  const { isLoading, isOpen, close, assignment, userAssignment, grade } = props;
  const [gradeStatus, setGradeStatus] = useState(0);
  const [gradeFeedback, setGradeFeedback] = useState('');

  useEffect(() => {
    if (userAssignment) {
      setGradeStatus(userAssignment.gradeStatus);
      setGradeFeedback(unescape(userAssignment.gradeFeedback));
    }

    return () => {
      setGradeStatus(0);
      setGradeFeedback('');
    };
  }, [userAssignment]);

  const handleClose = () => {
    close();
  };

  const handleGrade = () => {
    if (gradeStatus !== 1 && gradeStatus !== 2) {
      Swal.fire({
        title: '請選擇 合格 或 退回',
        icon: 'error',
      });

      return false;
    }

    grade(userAssignment.userAssignmentId, gradeStatus, escape(gradeFeedback));
  };

  const fileFullUrl = userAssignment
    ? `${userAssignment.fileUrl}${userAssignment.filePath}/${userAssignment.fileName}`
    : '';

  if (!assignment || !userAssignment) {
    return null;
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogTitle>
        {assignment.name}
        <div>{userAssignment.realName}</div>
      </DialogTitle>

      <DialogContent>
        <div style={{ maxHeight: '55vh', overflow: 'auto' }}>
          {Map.SubmissionTypes[assignment.submissionType] &&
            Map.SubmissionTypes[assignment.submissionType].type === 'video' && (
              <QuestionVideo
                videoName={userAssignment.fileName}
                videoPath={userAssignment.filePath}
                open
              />
            )}

          {Map.SubmissionTypes[assignment.submissionType] &&
            Map.SubmissionTypes[assignment.submissionType].type === 'word' && (
              <div style={{ margin: 'auto', textAlign: 'center' }}>
                <div
                  style={{
                    margin: '24px auto',
                    fontSize: '1rem',
                    letterSpacing: '1px',
                    color: '#999',
                  }}>
                  <span>檔名 </span>
                  {userAssignment.fileName}
                  <br />
                  WORD 僅提供下載
                </div>
                <a href={fileFullUrl} target="_blank">
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<CloudDownloadOutlinedIcon />}
                    size="large">
                    檔案下載
                  </Button>
                </a>
              </div>
            )}

          {Map.SubmissionTypes[assignment.submissionType] &&
            Map.SubmissionTypes[assignment.submissionType].type === 'pdf' && (
              <div>
                <PDFViewer pdfUrl={fileFullUrl} />
              </div>
            )}
        </div>

        <div style={{ marginTop: '24px' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              alignItems: 'stretch',
            }}>
            <div style={{ flexGrow: '1', margin: '12px auto' }}>
              <div>批改</div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                  justifyContent: 'center',
                  height: '80%',
                }}>
                <div
                  style={{
                    margin: 'auto 6px auto',
                    border: `3px solid ${
                      gradeStatus === 1 ? '#0bbd0b' : '#999'
                    }`,
                    borderRadius: '12px',
                    padding: '12px',
                    cursor: 'pointer',
                    backgroundColor: gradeStatus === 1 ? '#0bbd0b' : 'inherit',
                    color: gradeStatus === 1 ? '#fff' : 'inherit',
                  }}
                  onClick={() => {
                    setGradeStatus(1);
                  }}>
                  <CheckCircleOutlineRoundedIcon />
                  <div>合格</div>
                </div>

                <div
                  style={{
                    margin: 'auto 6px auto',
                    border: `3px solid ${
                      gradeStatus === 2 ? '#ff6e6e' : '#999'
                    }`,
                    borderRadius: '12px',
                    padding: '12px',
                    cursor: 'pointer',
                    backgroundColor: gradeStatus === 2 ? '#ff6e6e' : '#fff',
                    color: gradeStatus === 2 ? '#fff' : 'inherit',
                  }}
                  onClick={() => {
                    setGradeStatus(2);
                  }}>
                  <CancelRoundedIcon />
                  <div>退回</div>
                </div>
              </div>
            </div>

            <div style={{ flexGrow: '2' }}>
              <div>批改回覆</div>
              <Editor
                content={gradeFeedback}
                setContent={(newContent) => {
                  setGradeFeedback(newContent);
                }}
                editorProps={{
                  height: '150',
                }}
                disabled={isLoading}
              />
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" disabled={isLoading}>
          關閉
        </Button>
        <Button
          variant="contained"
          onClick={handleGrade}
          color="secondary"
          disabled={isLoading}>
          批改
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserAssignmentModal;
