import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch } from 'react-router';
import { Route } from 'react-router-dom';
import {
  createMuiTheme,
  ThemeProvider,
  StylesProvider,
} from '@material-ui/core/styles';
import Header from './components/Header';
import Footer from './components/Footer';
import Sidebar from './components/Sidebar';
import LoginPage from './containers/LoginPage';
import LogoutPage from './containers/LogoutPage';
import Page404 from './components/Page404';
import Page500 from './components/Page500';
import PrivateRoute from './components/PrivateRoute';
import Home from './containers/Home/Home';
import Advertising from './containers/Advertisting/Advertising';
import MemberManagement from './containers/Member/MemberManagement';
import OrderList from './containers/OrderList/OrderList';
import SaleExam from './containers/SaleExam/SaleExam';
import SetExam from './containers/SetExam/SetExam';
import Secret from './containers/Secret/Secret';
import PromoList from './containers/PromoList/PromoList';
import VideoRoute from './containers/Video/VideoRoute';
import HandoutRoute from './containers/Handout/HandoutRoute';
import TeacherRoute from './containers/Teacher/TeacherRoute';
import './style/app.scss';
import 'date-fns';
import zhTwLocale from 'date-fns/locale/zh-TW';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import PageLoading from './components/PageLoading';
import FlashMessage from './components/FlashMessage';
import { SnackbarProvider } from 'notistack';
import { zhTW } from './data/material-ui-locale-zhTW';
import Index from './containers/Restaurant/Index';
import Guest from './containers/Bonus/Guest';
import Markup from './containers/Bonus/Markup';
import BonusList from './containers/Bonus/BonusList';
import BonusAuditList from './containers/Bonus/AuditList';
import RefreshToken from './containers/RefreshToken';
import PhoneConfirmCodeList from './containers/PhoneConfirmCodeList';
import BonusProductRoute from './containers/BonusProduct/BonusProductRotue';
import { checkRole } from './Util/Util';
import { UserRoleIds } from './data/common';

const customTheme = createMuiTheme(
  {
    typography: {
      fontFamily: `PingFangSC-Regular, 'Noto Sans TC', '微軟正黑體', sans-serif`,
    },
  },
  zhTW,
);

class App extends Component {
  render() {
    const { loginState } = this.props;
    const isAdmin = checkRole(UserRoleIds.WISDOMBANK_ADMIN, loginState.roleIds);
    const isRestaurantStaff = checkRole(
      UserRoleIds.RESTAURANT_STAFF,
      loginState.roleIds,
    );
    const isTeacherEducation = checkRole(
      UserRoleIds.TEACHER_EDUCATION,
      loginState.roleIds,
    );

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhTwLocale}>
        <ThemeProvider theme={customTheme}>
          <StylesProvider injectFirst>
            <SnackbarProvider maxSnack={3} autoHideDuration={1000}>
              <div className="app">
                <RefreshToken />
                <PageLoading />
                <FlashMessage />
                <Header />
                <div
                  className="container"
                  style={{
                    paddingTop: loginState.isLogin ? '' : '0px',
                  }}>
                  <input type="checkbox" id="toggle-menu" />
                  <div
                    className={
                      loginState.isLogin
                        ? 'sidebar-box'
                        : 'sidebar-box not-Login'
                    }>
                    <Sidebar />
                  </div>
                  <div
                    className="main"
                    style={{
                      width: !loginState.isLogin ? '100%' : '',
                    }}>
                    <Switch>
                      <Route path="/Login" component={LoginPage} />
                      <Route path="/Logout" component={LogoutPage} />
                      <Route path="/errorPage" component={Page500} />

                      {isAdmin && (
                        <>
                          <PrivateRoute exact path="/" component={Home} />
                          <PrivateRoute
                            path="/Advertising"
                            component={Advertising}
                          />
                          <PrivateRoute
                            path="/MemberManagement"
                            component={MemberManagement}
                          />
                          <PrivateRoute
                            path="/OrderList"
                            component={OrderList}
                          />
                          <PrivateRoute path="/SaleExam" component={SaleExam} />
                          <PrivateRoute
                            exact
                            path="/SetExam/edit/:QuestionBankId"
                            component={SetExam}
                          />
                          <PrivateRoute
                            exact
                            path="/SetExam"
                            component={SetExam}
                          />
                          <PrivateRoute path="/Secret" component={Secret} />
                          <PrivateRoute
                            path="/Teacher"
                            component={TeacherRoute}
                          />
                          <PrivateRoute
                            path="/PromoList"
                            component={PromoList}
                          />
                          <PrivateRoute path="/Video" component={VideoRoute} />
                          <PrivateRoute
                            path="/Handout"
                            component={HandoutRoute}
                          />
                          <PrivateRoute
                            exact
                            path="/Bonus/Guest"
                            component={Guest}
                          />
                          <PrivateRoute
                            exact
                            path="/Bonus/Markup"
                            component={Markup}
                          />
                          <PrivateRoute
                            exact
                            path="/Bonus/BonusList"
                            component={BonusList}
                          />
                          <PrivateRoute
                            exact
                            path="/Bonus/AuditList"
                            component={BonusAuditList}
                          />
                          <PrivateRoute
                            exact
                            path="/PhoneConfirmCodeList"
                            component={PhoneConfirmCodeList}
                          />
                          <PrivateRoute exact path="/Bonus" component={Index} />
                          <PrivateRoute
                            path="/BonusProducts"
                            component={BonusProductRoute}
                          />
                          <PrivateRoute
                            path="/BonusProduct"
                            component={BonusProductRoute}
                          />
                        </>
                      )}

                      {isRestaurantStaff && !isAdmin && (
                        <>
                          <PrivateRoute exact path="/" component={Index} />
                          <PrivateRoute
                            exact
                            path="/PhoneConfirmCodeList"
                            component={PhoneConfirmCodeList}
                          />
                          <PrivateRoute
                            exact
                            path="/Bonus/Guest"
                            component={Guest}
                          />
                          <PrivateRoute
                            exact
                            path="/Bonus/Markup"
                            component={Markup}
                          />
                          <PrivateRoute exact path="/Bonus" component={Index} />
                        </>
                      )}

                      {isTeacherEducation && !isAdmin && (
                        <>
                          <PrivateRoute path="/Secret" component={Secret} />
                        </>
                      )}

                      <PrivateRoute component={Page404} />
                    </Switch>
                    <Footer />
                  </div>
                </div>
              </div>
            </SnackbarProvider>
          </StylesProvider>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    );
  }
}

const mapStateToProps = (store) => ({
  loginState: store.loginState,
});

export default connect(mapStateToProps)(App);
