import React, { useEffect, useState } from 'react';
import teacherAPI from '../../middleware/teacherAPI';
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { map, trim } from 'lodash';
import TableLoading from '../../components/TableLoading';
import TableEmpty from '../../components/TableEmpty';
import Swal from 'sweetalert2';

const TeacherUserList = (props) => {
  const { teacherId } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState();

  useEffect(() => {
    if (teacherId) {
      getUsers();
    }
  }, [teacherId]);

  const getUsers = () => {
    setIsLoading(true);

    teacherAPI
      .getUsers(teacherId)
      .then((rs) => {
        setUsers(rs);
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  const handleDelUser = (userId) => {
    Swal.fire({
      title: '確認要刪除關聯嗎？',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: '刪除',
      cancelButtonText: '取消',
    }).then((rs) => {
      if (rs.value === true) {
        setIsLoading(true);

        teacherAPI.delUser(teacherId, userId).then(
          (rs) => {
            getUsers();
          },
          (error) => {
            setIsLoading(false);
          },
        );
      }
    });
  };

  return (
    <div>
      <List>
        {!isLoading &&
          users &&
          map(users, (user) => {
            const account =
              trim(user.cellPhone) !== '' ? user.cellPhone : user.email;

            return (
              <ListItem key={user.id}>
                <ListItemAvatar>
                  <Avatar>{user.realName.charAt(0)}</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={`${account}`}
                  secondary={`ID ${user.id} - ${user.realName}`}
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" onClick={() => handleDelUser(user.id)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}

        {!isLoading && users && users.length <= 0 && (
          <TableEmpty message="目前沒有設定關聯帳號" />
        )}

        {isLoading && <TableLoading />}
      </List>
    </div>
  );
};
export default TeacherUserList;
