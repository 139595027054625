import { Button, ButtonGroup, Grid, TextField } from '@material-ui/core';
import React from 'react';
import Editor from '../Editor';
import { map } from 'lodash';
import Map from '../../data/Map';

const ResourceAssignment = (props) => {
  const { resource, setResource } = props;

  return (
    <div className="add--resource__inputs">
      <TextField
        label="作業名稱"
        fullWidth
        value={resource.name}
        onChange={(e) => {
          setResource({ ...resource, name: e.target.value });
        }}
      />

      <div>內容說明</div>

      <Editor
        content={resource.description}
        setContent={(newContent) => {
          setResource({
            ...resource,
            description: newContent,
          });
        }}
        imageS3Position={{
          bucket: 'questionbank-resource',
          dir: 'HangoutPic/material/Assignment',
          acl: 'public-read',
        }}
        editorProps={{
          height: 200,
          image_dimensions: false,
        }}
      />

      <div>繳交設定</div>

      <Grid
        container
        direction="row"
        justifycontent="space-around"
        alignItems="center"
        spacing={3}>
        <Grid item xs={6}>
          <div>上傳檔案</div>

          <ButtonGroup>
            {map(Map.SubmissionTypes, (type, typeId) => {
              const isSelected =
                parseInt(resource.submissionType, 10) === parseInt(typeId, 10);

              return (
                <Button
                  key={typeId}
                  onClick={() => {
                    setResource({
                      ...resource,
                      submissionType: typeId,
                    });
                  }}
                  color={isSelected ? 'primary' : 'default'}
                  variant={isSelected ? 'contained' : 'outlined'}>
                  {type.name}
                </Button>
              );
            })}
          </ButtonGroup>
        </Grid>

        <Grid item xs={6}>
          <div>上傳次數</div>

          <ButtonGroup>
            <Button
              color={resource.submissionCount === 1 ? 'primary' : 'default'}
              variant={
                resource.submissionCount === 1 ? 'contained' : 'outlined'
              }
              onClick={() => {
                setResource({
                  ...resource,
                  submissionCount: 1,
                });
              }}>
              一次
            </Button>
            <Button
              color={resource.submissionCount === null ? 'primary' : 'default'}
              variant={
                resource.submissionCount === null ? 'contained' : 'outlined'
              }
              onClick={() => {
                setResource({
                  ...resource,
                  submissionCount: null,
                });
              }}>
              無限
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    </div>
  );
};

export default ResourceAssignment;
