import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { editHangoutStateAPI } from '../../store/action';
import BreakCrumbs from '../../components/BreakCrumbs';
import { Box, Paper, Grid, Typography, Divider } from '@material-ui/core';
import TableLoading from '../../components/TableLoading';
import { useLocation } from 'react-router';
import { forEach, unescape, map } from 'lodash';
import Map from '../../data/Map';
import ChapterRow from '../../components/Secret/ChapterRow';
import { alertError, numberToChineseWords } from '../../Util/Util';
import UserAssignmentsAccordion from '../../components/Secret/UserAssignmentsAccordion';
import hangoutAPI from '../../middleware/hangoutAPI';

const parseChapter = (chapter) => {
  let newChapter = {
    name: chapter.Name,
    id: chapter.HangoutChapterId,
    assignments: [],
  };

  if (Array.isArray(chapter.ResourceList)) {
    forEach(chapter.ResourceList, (resource) => {
      if (
        Map.MaterialTypeNumbers[resource.MaterialType] &&
        Map.MaterialTypeNumbers[resource.MaterialType].type === 'assignment'
      ) {
        let newAssignment = {
          id: resource.AssignmentId,
          canGradeStudentCount: resource.SubmitStudentCount, // gradeStatus = 0 可批改數量
          passStudentCount: resource.GradeStudentCount, // gradeStatus = 1 通過數量
          name: resource.Name,
          description: unescape(resource.Description),
          submissionType: resource.SubmissionType,
          submissionCount: resource.SubmissionCount,
        };

        newChapter.assignments.push(newAssignment);
      }
    });
  }

  return newChapter;
};

const parseHangout = (hangout) => {
  let chapters = [];

  if (Array.isArray(hangout.HangoutChapters)) {
    forEach(hangout.HangoutChapters, (chapter) => {
      let newChapter = parseChapter(chapter);

      if (Array.isArray(chapter.HangoutUnits)) {
        newChapter.units = [];

        forEach(chapter.HangoutUnits, (unit) => {
          newChapter.units.push(parseChapter(unit));
        });
      }

      chapters.push(newChapter);
    });
  }

  return {
    name: hangout.Name,
    id: hangout.HangoutId,
    studentCount: hangout.TotalStudentCount,
    chapters: chapters,
  };
};

const SecretUserAssignments = (props) => {
  const { editHangoutStateAPI } = props;
  const { hangoutId } = useParams();
  const location = useLocation();
  const [hangout, setHangout] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isUserAssignmentsLoading, setIsUserAssignmentsLoading] = useState(
    false,
  );
  const [expandedAssignment, setExpandedAssignment] = useState();
  const [userAssignments, setUserAssignments] = useState();

  useEffect(() => {
    setIsLoading(true);

    loadHangout(() => {
      setIsLoading(false);
    });
  }, [hangoutId]);

  const loadHangout = (callback) => {
    editHangoutStateAPI(hangoutId, (thisHangout) => {
      setHangout(parseHangout(thisHangout));

      if (callback) {
        callback();
      }
    });
  };

  const openUserAssignments = (assignment) => {
    const isOpenAction = assignment.id !== expandedAssignment;

    if (isOpenAction) {
      setIsUserAssignmentsLoading(true);

      loadUserAssignments(hangoutId, assignment.id).then(() => {
        setIsUserAssignmentsLoading(false);
      });
    } else {
      setExpandedAssignment(undefined);
      setUserAssignments();
    }
  };

  const loadUserAssignments = (hangoutId, assignmentId) => {
    return hangoutAPI.getUserAssignments(hangoutId, assignmentId).then(
      (rs) => {
        setExpandedAssignment(assignmentId);
        setUserAssignments(rs);
      },
      (error) => {
        alertError(error);
      },
    );
  };

  const grade = (userAssignmentId, gradeStatus, gradeFeedback) => {
    setIsLoading(true);

    hangoutAPI
      .gradeUserAssignment(userAssignmentId, gradeStatus, gradeFeedback)
      .then((rs) => {
        loadHangout(() => {
          setIsLoading(false);

          if (expandedAssignment) {
            setIsUserAssignmentsLoading(true);

            loadUserAssignments(hangoutId, expandedAssignment).then(() => {
              setIsUserAssignmentsLoading(false);
            });
          }
        });
      });
  };

  return (
    <div className="popular pagefadeIn">
      <BreakCrumbs path={location.pathname}></BreakCrumbs>

      {isLoading && <TableLoading />}

      {!isLoading && hangout && (
        <Box component={Paper} square elevation={2} p={1} mt={3} mb={3}>
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h4" color="textPrimary" gutterBottom>
                {hangout.name} 作業批改
              </Typography>
            </Grid>
          </Grid>

          <Divider light />

          <Box mt={2} mb={2}>
            {hangout.chapters.map((chapter, chapterKey) => {
              return (
                <Box key={chapter.id} mb={3} mt={1}>
                  <ChapterRow
                    chapter={chapter}
                    chapterName={`第${numberToChineseWords(chapterKey + 1)}章`}
                  />

                  {chapter.assignments && chapter.assignments.length > 0 && (
                    <>
                      {chapter.assignments.map((assignment) => {
                        const isExpanded = expandedAssignment === assignment.id;

                        return (
                          <UserAssignmentsAccordion
                            key={assignment.id}
                            isExpanded={isExpanded}
                            clickAction={openUserAssignments}
                            assignment={assignment}
                            userAssignments={userAssignments}
                            isLoading={isUserAssignmentsLoading}
                            studentCount={hangout.studentCount}
                            grade={grade}
                          />
                        );
                      })}
                    </>
                  )}

                  <Box style={{ paddingLeft: '50px' }}>
                    {chapter.units.map((unit, unitKey) => (
                      <div key={unit.id}>
                        <ChapterRow
                          chapter={unit}
                          chapterName={`單元${unitKey + 1}`}
                        />

                        {unit.assignments && unit.assignments.length > 0 && (
                          <>
                            {unit.assignments.map((assignment) => {
                              const isExpanded =
                                expandedAssignment === assignment.id;

                              return (
                                <UserAssignmentsAccordion
                                  key={assignment.id}
                                  isExpanded={isExpanded}
                                  clickAction={openUserAssignments}
                                  assignment={assignment}
                                  userAssignments={userAssignments}
                                  isLoading={isUserAssignmentsLoading}
                                  studentCount={hangout.studentCount}
                                  grade={grade}
                                />
                              );
                            })}
                          </>
                        )}
                      </div>
                    ))}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
    </div>
  );
};

export default connect(null, { editHangoutStateAPI })(SecretUserAssignments);
