import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    fontSize: '1rem',
    margin: theme.spacing(2, 0),
  },
  chapterName: {
    fontSize: '0.9rem',
    fontWeight: '600',
    margin: 'auto 6px',
  },
  content: {
    padding: '3px 12px',
    borderRadius: '6px',
    backgroundColor: '#f5dbdb',
  },
}));

const ChapterRow = (props) => {
  const { chapter, chapterName } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.chapterName}>{chapterName}</div>
      <div className={classes.content}>{chapter.name}</div>
    </div>
  );
};

export default ChapterRow;
