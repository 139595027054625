import React, { useEffect, useState } from 'react';
import BreakCrumbs from '../../components/BreakCrumbs';
import {
  Box,
  Grid,
  Paper,
  Typography,
  Button,
  TextField,
  Divider,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import TableLoading from '../../components/TableLoading';
import TableError from '../../components/TableError';
import TableEmpty from '../../components/TableEmpty';
import { initHangoutList } from '../../Util/fetchMethods';
import { LIST_PAGE_COUNT } from '../../data/common';
import Swal from 'sweetalert2';
import { join } from 'lodash';
import HangoutListTable from '../../components/Secret/HangoutListTable';
import { useHistory } from 'react-router';

const SecretAssignmentList = (props) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [pageStatus, setPageStatus] = useState('list');
  const [hangouts, setHangouts] = useState([]);
  const [pageParams, setPageParams] = useState({
    currentPage: 1,
    perPageCount: LIST_PAGE_COUNT,
  });
  const [pageTotal, setPageTotal] = useState(0);

  useEffect(() => {
    loadHangouts();
  }, [pageParams]);

  const handleSearch = () => {
    setPageParams({
      ...pageParams,
      currentPage: 1,
    });
  };

  const loadHangouts = () => {
    setIsLoading(true);

    initHangoutList({
      keyword: keyword || '',
      page: pageParams.currentPage,
      count: pageParams.perPageCount,
    })
      .then((rs) => {
        if (rs.Status === 1) {
          setHangouts(rs.Result.HangoutList);
          setPageTotal(rs.Result.TotalPages);
        } else {
          Swal.fire({
            title: '課程讀取失敗',
            html: Array.isArray(rs.Message)
              ? join(rs.Message, '<br />')
              : rs.Message,
            icon: 'error',
          });
        }
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  const changeCurrentPage = (changePage) => {
    setPageParams({
      ...pageParams,
      currentPage: changePage,
    });
  };

  const toHangoutUserAssignment = (hangout) => {
    history.push(`/Secret/${hangout.HangoutId}/UserAssignments`);
  };

  return (
    <div className="popular pagefadeIn">
      <BreakCrumbs path={props.match.url}></BreakCrumbs>

      <Box component={Paper} square elevation={2} p={1} mt={3} mb={3}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h4" color="textPrimary" gutterBottom>
              課程列表
            </Typography>
          </Grid>
        </Grid>

        <Divider light />

        <Box mt={2} mb={2}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="flex-end"
            spacing={2}>
            <Grid item>
              <TextField
                label="輸入課程名稱搜尋"
                type="search"
                variant="outlined"
                size="small"
                disabled={isLoading}
                value={keyword}
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleSearch();
                  }
                }}
              />
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="primary"
                startIcon={<SearchIcon />}
                disabled={isLoading}
                onClick={handleSearch}>
                搜尋
              </Button>
            </Grid>
          </Grid>
        </Box>

        {isLoading && <TableLoading />}

        {!isLoading && (!hangouts || hangouts.length <= 0) && (
          <TableEmpty message="沒有課程" />
        )}

        {!isLoading && hangouts && hangouts.length > 0 && (
          <HangoutListTable
            hangouts={hangouts}
            pageTotal={pageTotal}
            page={pageParams.currentPage}
            setPage={changeCurrentPage}
            perPageCount={pageParams.perPageCount}
            toHangoutUserAssignment={toHangoutUserAssignment}
          />
        )}
      </Box>
    </div>
  );
};

export default SecretAssignmentList;
