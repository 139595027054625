import { IconButton } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import ArrowDropUpRoundedIcon from '@material-ui/icons/ArrowDropUpRounded';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import ZoomInRoundedIcon from '@material-ui/icons/ZoomInRounded';
import ZoomOutRoundedIcon from '@material-ui/icons/ZoomOutRounded';

const scales = [0.5, 1, 1.5, 2, 2.5, 3];

const PDFViewer = (props) => {
  const { pdfUrl } = props;
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [scaleIndex, setScaleIndex] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    return () => {
      setNumPages(undefined);
      setPageNumber(1);
      setScaleIndex(1);
    };
  }, [pdfUrl]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setIsLoading(false);
  };

  const prevPage = () => {
    setPageNumber(pageNumber - 1);
  };

  const nextPage = () => {
    setPageNumber(pageNumber + 1);
  };

  const zoomIn = () => {
    setScaleIndex(scaleIndex + 1);
  };

  const zoomOut = () => {
    setScaleIndex(scaleIndex - 1);
  };

  return (
    <div>
      <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} scale={scales[scaleIndex]} />
      </Document>

      <div
        style={{
          position: 'absolute',
          top: '10px',
          left: '0',
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'nowrap',
          alignItems: 'center',
        }}>
        <IconButton onClick={prevPage} disabled={isLoading || pageNumber <= 1}>
          <ArrowDropUpRoundedIcon />
        </IconButton>

        <IconButton
          onClick={nextPage}
          disabled={isLoading || pageNumber >= numPages}>
          <ArrowDropDownRoundedIcon />
        </IconButton>

        <IconButton
          onClick={zoomIn}
          disabled={isLoading || scaleIndex + 1 >= scales.length}>
          <ZoomInRoundedIcon />
        </IconButton>

        <IconButton onClick={zoomOut} disabled={isLoading || scaleIndex <= 0}>
          <ZoomOutRoundedIcon />
        </IconButton>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            border: '1px solid #999',
            backgroundColor: '#eee',
            padding: '3px 6px',
            borderRadius: '12px',
          }}>
          <div style={{ borderBottom: '2px solid #ababab' }}>{pageNumber}</div>
          <div>{numPages}</div>
        </div>
      </div>
    </div>
  );
};

export default PDFViewer;
